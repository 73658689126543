.report-list {
  .col-md-6 {
    margin-bottom: 20px;
    button {
      width: 80%;
    }
  }
}

.report-container {
  .report-filters {
    margin-top: 20px;
    margin-bottom: 20px;

    .inline {
      & > div {
        display: inline-block;
      }
    }
  }
  .extra-stats {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .fa-times {
    color: red;
  }
  .fa-check {
    color: green;
  }
  a {
    text-decoration: underline;
    color: lightskyblue;
  }
  .table {
    .star-ratings {
      width: 60px;
    }

    .metricDrilldown {
      cursor: pointer;
      color: darkslateblue;
    }
  }
  .raw-metrics-table {
    padding-top: 10px;

    table {
      background: none;
      margin-left: -5px;
      font-style: italic;

      th {
        font-weight: 400;
      }
    }
  }
}

#metric-report-wrapper {
  .checkboxes-div {
    display: flex;
    justify-content: space-around;
  }
}
