.wrapper {
  margin-top: 30px;

  .title {
    font-size: 18px;
    font-weight: bold;
  }

  .subtitle {
    font-size: 14px;
  }

  .noData {
    margin: 30px auto 100px;
    width: 100%;
    font-size: 20px;
    background-color: lightgray;
    text-align: center;
  }
}
