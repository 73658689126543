.summit-attendee-form {
  .ticket-component {
    .add-ticket {
      margin-left: 20px;
    }
    .ticket-btn {
      margin: 0 20px 5px 0;
    }
  }

  .order-component {
    .order-btn {
      margin: 0 20px 5px 0;
    }
  }

  .member-input {
    > div {
      width: 350px;
    }
  }

  .current_affiliation {
    margin-top: 30px;
  }
}
