.filters-row {
  margin: 25px -15px 0px;
  display: flex;
  flex-wrap: wrap;
  align-items: start;

  .col-md-6,
  .col-md-3 {
    margin-bottom: 10px;
  }
}

.event-list-date-picker {
  > div:first-of-type {
    width: 100%;
  }
}

@media (max-width: 991px) {
  .filters-row {
    display: block;
  }
}
