.wrapper {
  margin-top: 30px;

  .title {
    font-size: 18px;
    font-weight: bold;
  }

  .subtitle {
    font-size: 14px;
  }
}
