body {
  background-color: whitesmoke;
}

#root {
}

.header {
  height: 64px;
  border-bottom: 1px solid #b3b3b3;
  background-color: white;
}

.header-title {
  font-size: 16px;
  text-align: center;
  height: 100%;
  line-height: 60px;
}

.user-menu {
  position: absolute;
  top: 7px;
  right: 30px;
  height: 40px;
  width: 140px;
  cursor: pointer;
}

.login {
  margin-top: 60px;
  padding: 30px 0 50px 0;
  text-align: center;
  font-size: 22px;
}

.logout {
  position: absolute;
  top: 4px;
  right: 55px;
}

.profile-pic {
  height: 40px;
  width: 40px;
  border: 1px solid #afafaf;
  border-radius: 20px;
  overflow: hidden;
  float: right;
  background-size: cover;
}

.primary-layout {
  margin: 30px 0;

  @media (min-width: 768px) {
    margin: 30px 0 30px 40px;
  }
}

.container {
  background-color: white;
  padding: 30px;
}

.form-check-label {
  padding-left: 10px !important;
  padding-top: 2px;
  font-weight: 400;
}

.not-allowed-page {
  margin-top: 20px;
}

.not-allowed-page h3,
.not-allowed-page h1 {
  text-align: center;
}

/* MOBILE SPECIFIC */

@media only screen and (max-width: 768px) {
  .container {
    width: 100%;
  }

  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-6,
  .col-md-8,
  .col-md-12 {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
