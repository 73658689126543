.container.large {
  width: 95%;
}

.right-space {
  margin-right: 20px;
}

.left-space {
  margin-left: 20px;
}

h3 {
  margin-bottom: 20px;
}

.inline {
  & > div {
    display: inline-block;
  }
}

.panel-group {
  margin-bottom: 30px;
}

.panel a {
  cursor: pointer;
  text-decoration: none;
  color: black;

  .panel-heading {
    &:hover {
      background-color: #f5f5f5;
    }
  }
}

.panel a.collapsed {
  .panel-heading {
    background-color: #f5f5f5;
  }
  .panel-title:after {
    content: "\F078";
  }
}

.panel .panel-title:after {
  font-family: Fontawesome;
  content: "\F077";
  float: right;
  font-size: 10px;
  font-weight: 300;
  cursor: pointer;
}

.checkboxes-div {
  margin-top: 30px;
  margin-bottom: 5px;
}

.form-group {
  margin-bottom: 25px;
}

.breadcrumbs-wrapper {
  padding: 10px 30px;
  margin-bottom: 20px;
  color: grey;
  font-size: 12px;
  background-color: white;
}

.breadcrumbs-wrapper:not(.container) {
  padding: 10px 12px;
  margin: 0 16px 20px 16px;
}

.breadcrumbs__separator {
  margin: 0 5px;
}

.breadcrumbs__crumb--active {
  font-weight: bold;
  color: black;
  cursor: default;
}

.breadcrumbs__crumb--active:hover {
  text-decoration: none;
  color: black;
}

.swal2-popup {
  font-size: 1.6rem !important;
}

.summit-dropdown {
  .summit-select {
    width: 170px !important;
  }
  .btn {
    height: 38px !important;
  }
}

.badge-warning {
  background-color: yellow;
  color: #373a47;
}

.selected-files {
  .file-box {
    max-height: 100px;
    max-width: 80px;
    overflow: hidden;
    text-overflow: ellipsis;

    a {
      white-space: nowrap;
    }

    .remove {
      top: 0;
      right: 0;
    }
  }
}

.actions-wrapper {
  margin-bottom: 30px;

  .row {
    margin-bottom: 10px;
  }
}

.no-items {
  margin-top: 30px;
}
.info-help {
  cursor: pointer;
}

.character-counter {
  float: right;
  margin-top: 5px;
  color: #555;
  font-size: 12px;
  padding-right: 2px;
}

.note {
  font-size: 12px;
  font-weight: bold;
}

.email-flow-table-wrapper {
  > div:first-child {
    overflow-y: auto;
  }
}

.refund-input-wrapper {
  input {
    padding-right: 25px;
  }
  i {
    position: absolute;
    top: 10px;
    right: 25px;
  }
}

.editable-table-wrapper {
  .editable-table-buttons-row {
    margin-bottom: 20px;
  }
}

.text-button {
  border: none;
  background-color: transparent;
  padding: 0;
}

.text-link-button {
  border: none;
  background-color: transparent;
  padding: 0;
  text-align: left;
  text-decoration: underline;
  &:hover {
    font-weight: bold;
  }
}

.text-table-link {
  border: none;
  background-color: transparent;
  padding: 0;
  text-align: left;
  color: #337ab7;
  text-decoration: none;
  &:hover {
    color: #23527c;
    text-decoration: underline;
  }
}

.registration_disclaimer_input {
  position: relative;
  z-index: 0;
}

.copy-button {
  cursor: pointer;
}
