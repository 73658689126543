.dashboard {
  .row {
    font-size: 20px;
    margin: 20px 0;
  }
  .current {
    color: #00ca00;
  }
  .past {
    color: red;
  }
  .future {
    color: black;
  }
  i.clickable {
    cursor: pointer;
  }
}
