#summit_table {
  margin-top: 20px;
  .actions {
    text-align: right;
    width: unset;

    .btn {
      margin-right: 10px;
    }
  }
}
