.header {
  .reload,
  .view {
    margin-top: 25px;
  }
}

.list {
  margin-top: 80px;
}
