.containerRight {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.buttonsPanelRight {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.containerBottom {
  display: flex;
  margin-bottom: 8px;
  position: relative;
  height: 70px;
}

.buttonsPanelBottom {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: row-reverse;
  gap: 5px;
}

.contentBody {
  flex: 1;
  margin-right: 8px;
}

.button {
  height: 35px;
  width: 35px;
  border-radius: 50%;
  border: none;
  box-shadow: none;
  outline: none;
  cursor: pointer;
}

.addButtonRight {
  .button;
  background-color: #337ab7;
  color: white;
}

.deleteButtonRight {
  .button;
  background-color: lightgray;
}

.addButtonBottom {
  .button;
  height: 25px;
  width: 25px;
  background-color: #337ab7;
  color: white;
}

.deleteButtonBottom {
  .button;
  height: 25px;
  width: 25px;
  background-color: lightgray;
}
