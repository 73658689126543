.saveFilterWrapper {
  display: flex;
  align-items: baseline;
  margin-top: 20px;
  .saveAs {
    display: flex;
    align-items: baseline;
    > div {
      width: 80%;
      margin-left: auto;
    }
  }
  .visibleTo {
    display: flex;
  }
}
