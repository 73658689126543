.extra-questions-wrapper {
  > form {
    display: flex;
    width: 100%;
    flex-direction: column;

    > div {
      .form-check.abc-radio {
        margin-left: 8px;
      }
    }

    [class="question-container checkbox-wrapper"] {
      display: flex;
      margin-bottom: 5px;

      > div {
        width: 35px;
      }
    }

    // style for questions with text input
    [class="question-container"] {
      flex-direction: column;
      align-items: start;
      gap: 0px;

      .question-control-container {
        width: 100%;
        .checkboxes-div {
          margin-top: 0px;
        }
      }
    }
  }
}
