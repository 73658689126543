.ticket-list-wrapper {
  .ticket-import-upload-wrapper {
    margin-top: 30px;
  }

  .ticket-ingest-email-wrapper {
    margin-top: 30px;
  }

  .buttons-wrapper {
    display: flex;
    justify-content: space-around;
    margin-top: 0.5em;
  }

  .search-section {
    display: flex;

    .search-container {
      flex: 1;
    }
  }
}

.filtersWrapper {
  margin-top: 25px;

  .dropdownFilter {
    padding-top: 10px;
    padding-bottom: 5px;
  }

  .segmentFilter {
    width: 100%;
    color: #337ab7;
    font-size: 10px;
    margin: 10px auto 0;
  }

  .abc-checkbox {
    padding-top: 16px;
    padding-bottom: 15px;
  }
}

.ticket-list-table {
  overflow: auto;
  max-height: 700px;
}

.modal_ingest {
  z-index: 100000000000000000000000000000000000 !important;
}
