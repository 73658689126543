/**
 * Copyright 2017 OpenStack Foundation
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 * http://www.apache.org/licenses/LICENSE-2.0
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 **/

.addNewNote {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid lightgray;

  input,
  textarea {
    height: 80px;
  }
}

.notesTable {
  td:not(:global(.actions)) a {
    text-decoration: underline;
    color: blue;
  }
}
