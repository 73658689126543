.modalWrapper {
  p {
    text-align: center;
  }
  textarea {
    width: 100%;
    height: 100px;
    padding: 10px;
  }
}
