.subtrackddl {
  width: 200px;
  .Select-control {
    border-radius: 4px 0 0 4px;
    height: 100%;
    .Select-input {
      height: 32px;
    }
  }
}

.subtrackddlgrp {
  & > div {
    float: left;
  }

  .add-button {
    height: 38px;
    border-color: #afafaf;
  }
}
