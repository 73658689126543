.merge-speaker-form {
  .field-box {
    margin-top: 10px;
    min-height: 45px;
    .field {
      background-color: rgba(211, 211, 211, 0.2);
      cursor: pointer;
      height: auto;
      min-height: 34px;
      img {
        height: 50px;
      }
    }
    .selected {
      background-color: rgba(0, 255, 0, 0.2);
    }
    .unselected {
      background-color: rgba(255, 0, 0, 0.2);
    }
  }
}

.merge-button-box {
  margin-top: 30px;
}
