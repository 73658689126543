h3 {
  display: flex;
  justify-content: space-between;

  .title {
    max-width: 750px;
  }

  .prev,
  .next {
    margin-right: 20px;
  }
}

.submit-buttons {
  margin-top: 30px;

  .btn {
    margin-left: 10px;
  }
}

.copy-button {
  cursor: pointer;
}

.published {
  text-align: center;
  .fa-times {
    color: red;
  }
  .fa-check {
    color: green;
  }
}
