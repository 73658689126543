.popover {
  position: absolute;
  z-index: 2;
  top: 30px;
  right: 25px;
}

.cover {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.colorWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.selectedColorContainer {
  display: flex;
  gap: 5px;
  height: 100%;
  justify-content: center;
  align-items: center;

  .colorSquare {
    border: 1px solid black;
    height: 100%;
    width: 20px;
  }

  .removeColor {
    background-color: transparent;
    border: none;
    transition: color 150ms;
    font-size: 16px;
    color: hsl(0, 0%, 80%);
    &:hover {
      color: hsl(0, 0%, 60%);
    }
  }
}
