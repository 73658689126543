.summit-speaker-form {
  .summit-data {
    .panel-title a {
      cursor: pointer;

      &:after {
        font-family: Fontawesome;
        content: "\f077";
        float: right;
        font-size: 10px;
        font-weight: 300;
        cursor: pointer;
      }
    }
    .panel-title a.collapsed {
      &:after {
        content: "\f078";
      }
    }
    .panel .panel-body .summit-data-section {
      margin-bottom: 20px;

      a {
        color: #337ab7;
        text-decoration: underline;
      }

      .btn {
        margin-top: 10px;
      }
    }
  }
}
