.allowed-members-panel {
  .allowed-members-header {
    margin: 20px 0;
    height: 36px;

    .input-group {
      width: 300px;
    }
  }
}
