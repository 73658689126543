.email-template-container {
  height: 960px;
  margin-bottom: 50px;
  .email-template-buttons {
    display: flex;
    flex-direction: row;

    > div {
      width: 50%;
      &:first-of-type {
        display: flex;
        align-items: end;
        justify-content: space-between;
        margin-right: 30px;
      }
      &:last-of-type {
        margin-left: 30px;
      }
    }
  }

  .email-template-content {
    display: flex;
    flex-direction: row;
    height: 100%;

    .email-template-code {
      min-width: 50%;
    }

    .email-template-content-buttons {
      width: 30px;
      display: flex;
      &.single-button {
        width: 20px;
      }

      button {
        width: 100%;
        padding: 0;
        background-color: #42474e;
        border-right: 1px solid #343436;
        color: #fff;
        cursor: pointer;
        flex: 1;
        font-size: 12px;
        justify-content: center;
        transition: background-color 0.3s ease-in-out;
        &:hover {
          background-color: #535a63;
        }
      }
    }

    .email-template-preview {
      min-width: 50%;
      position: relative;
      > iframe {
        border: none;
        display: block;
        margin: 0 auto;
        transform-origin: top left;
      }
    }
  }
}

.email-history-ddl {
  a {
    color: inherit;
  }
}

@media only screen and (max-width: 992px) {
  .email-template-container {
    .email-template-buttons {
      > div {
        width: 100%;
        &:first-of-type {
          margin-left: 0;
          > div:last-of-type {
            width: 100%;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .email-template-container {
    .email-template-buttons {
      > div {
        &:first-of-type {
          margin-left: 0;
          flex-direction: column;
          align-items: start;
          > div:last-of-type {
            padding: 0;
          }
        }
      }
    }
  }
}
