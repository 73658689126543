.wrapper {
  margin: 20px 0;

  label {
    margin: 0;
    padding: 0;

    span {
      position: relative;
      top: -10px;
      left: 10px;
    }
  }
}
