.badge-print-wrapper {
  display: flex;
  flex-direction: column;
}

.details {
  color: #337ab7;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    color: #23527c;
    text-decoration: underline;
  }
}

.filter-wrapper {
  margin-top: 5px;
  margin-bottom: 10px;
}

.date-wrapper {
  display: flex;
  gap: 20px;
  > div {
    width: 50%;
  }
}

.badge-print-date-picker {
  > input {
    height: 38px;
  }
}

.badge-print-datepicker {
  display: flex;
  align-items: center;
  div {
    z-index: 5;
  }
  i {
    color: #555;
    cursor: pointer;
    margin-left: -20px;
    z-index: 10;
  }
}
