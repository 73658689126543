.tree {
  list-style: none;
  margin: 0;
  padding: 5px;
}

.tree-node,
.tree-node-group {
  list-style: none;
  margin: 0;
  padding: 0;
}

.tree-branch-wrapper,
.tree-node__leaf {
  outline: none;
}

.tree-node__branch {
  display: block;
}

.tree-node {
  cursor: pointer;
}
