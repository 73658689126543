.edit-label-slider {
}

.label {
  vertical-align: super;
  padding-left: 10px;
  font-size: 12pt;
}

.action_link {
  vertical-align: super;
  padding-left: 10px;
}

.input_text {
  vertical-align: super;
  margin-left: 10px;
}
