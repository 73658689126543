.many-2-many-ddl-container {
  .many-2-many-ddl {
    float: left;
    width: 50%;
  }

  .many-2-many-ddl-button {
    padding: 8px 12px;
  }
}
