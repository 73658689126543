@import "schedule-builder-variables";

.unselectable-text() {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.unschedule-event {
  background-color: @unschedule_event_background_color;
  height: @event_initial_height !important;
  width: 100%;
  color: @unschedule_event_text_color;
  .no-margin;
  .row {
    height: 100%;
    .col-md-12 {
      height: 100%;
    }
  }
  .event-container {
    float: left;
    margin: 5px 10px;
    width: 90%;
    height: 20px;
    overflow: hidden;
    .no-padding;
  }
  .event-actions {
    float: right;
    margin: 0 5px 0 0;
  }
  .event-select-wrapper {
    margin: 5px 0 0 5px;
    float: left;
  }
}

.no-margin() {
  margin: 0px !important;
}

.no-padding() {
  padding: 0px !important;
}

.event-content {
  height: 100%;
  width: 100%;
}

.event-content-half {
  height: 90%;
  width: 50%;
}

.unschedule-list,
.schedule-list-search-results,
.empty-spots-list {
  list-style: none;
  width: 100%;
  padding-left: 0px;
  li {
    margin-bottom: @event_spacing;
  }
}

.published-container {
  .search-container {
    input,
    button {
      height: 38px;
    }
  }
}

.schedule-list-search-results {
  .event-container {
    float: left;
    margin: 5px 10px;
    width: 90%;
    height: 20px;
    overflow: hidden;
    .no-padding;
  }
  .event-actions {
    float: right;
    margin: 0 5px 0 0;
  }
}

.empty-spots-list {
  li {
    .empty-spot {
      background: gainsboro;
      height: @event_initial_height;
      cursor: pointer;
      position: relative;
      text-align: center;
      vertical-align: middle;
      padding-top: 5px;
      .to {
        padding-right: 10px;
        font-weight: bold;
      }
      .from {
        padding-right: 10px;
        font-weight: bold;
      }
      .gap {
        padding-right: 10px;
        font-weight: bold;
      }
    }
  }
}

.border-left() {
  border-left: 1px solid @schedule_border_color;
}

.border-bottom() {
  border-bottom: 1px solid @schedule_border_color;
}

.border-right() {
  border-right: 1px solid @schedule_border_color;
}

.no-padding {
  .no-padding;
}

.no-margin {
  .no-margin;
}

.swal2-container {
  z-index: @event_z_index + 2;
}

.event-type-selector,
.track-selector,
.presentation-selection-status-selector,
.search-container,
.order-by-selector {
  padding-bottom: 10px;
}

.event-type-selector,
.track-selector,
.presentation-selection-status-selector,
.order-by-selector {
  width: 100%;
}

.event-count {
  text-align: right;
}

.empty-list-message {
  font-weight: bold;
}

.fa-spinner {
  z-index: @event_z_index + 2;
}

.Select-menu-outer {
  z-index: @event_z_index + 2 !important;
}

.swal2-container {
  z-index: @event_z_index + 3 !important;
}

.select-event-btn {
  z-index: @event_z_index + 1;
  cursor: pointer;
}

.edit-unpublished-event-btn {
  cursor: pointer;
  padding-top: 8px;
  z-index: @event_z_index + 1;
}

.schedule-event-result {
  background-color: @schedule_event_background_color;
  height: @event_initial_height !important;
  width: 100%;
  position: relative;

  .edit-published-event-btn {
    cursor: pointer;
    padding-top: 10px;
    z-index: @event_z_index + 1;
    left: 97.5%;
    position: absolute;
  }
  .col-md-12 {
    padding: 1em;
  }
}

.popover {
  max-width: 60% !important;
  z-index: @event_z_index + 4 !important;
}

.event-title {
  &:hover {
    cursor: pointer;
  }
}

.modal {
  z-index: @event_z_index + 6 !important;
}

.ajax-loader-container {
  z-index: @event_z_index + 10 !important;
}
.bulk-actions-editor-container {
  margin: 1em;
  padding: 1em;
  background-color: #ffffff;
  .col-title {
    font-weight: bold;
    padding: 1em;
  }
  .col-form-buttons {
    button {
      margin-right: 10px;
    }
  }
}

.rank-status {
  float: right;

  &.alternate {
    color: yellow;
  }

  &.accepted {
    color: #0373c9;
  }
}
