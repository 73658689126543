.occupancyWrapper .container {
  @media only screen and (max-width: 600px) {
    padding: 10px;
  }
}

.exportButton {
  @media only screen and (max-width: 600px) {
    width: 100%;
    margin-top: 20px;
  }
}

.filters {
  margin-bottom: 30px;
  .currentEvents {
    margin-top: 6px;
    margin-bottom: 0px;
  }
  .currentEventButton {
    width: 100%;
    margin-top: 20px;
  }
}

.currentEventView {
  .occupancy {
    text-align: center;
    margin-top: 30px;
    .btn {
      font-size: 40px;
      width: 80px;
      height: 80px;
      border-radius: 40px;
    }
    span {
      margin: 20px;
      font-size: 20px;
    }
  }
}
